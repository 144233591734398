import request from "../utils/request";
// 表具类型》》》》获取
export function calorimeterTypeget (data) {
    return request({
      method: 'get',
      url: 'calorimeterType',
      params: data
    })
  }
  
  // 表具类型》》》》添加
  export function calorimeterTypepost (data) {
    return request({
      method: 'post',
      url: 'calorimeterType',
      data
    })
  }
  
  // 表具类型》》》》修改
  export function calorimeterTypeput (data) {
    return request({
      method: 'put',
      url: 'calorimeterType',
      data
    })
  }
  
  // 表具类型》》》》删除
  export function calorimeterTypedele (data) {
    return request({
      method: 'DELETE',
      url: 'calorimeterType',
      data
    })
  }